




































import { doGetSuggestInfo } from '@/api/suggest';
import dayjs from 'dayjs';

export const typeMap = {
  'NEW_FEATURES': '新特性',
  'FUNCTION_ABNORMAL': '功能异常',
  'SUGGESTION': '建议',
  'OTHER': '其他'
} as { [key: string]: any }
export default {
  props: {
    currentId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      suggestInfo: {
        title: '',
        typeName: '',
        createTime: '',
        describe: '',
        image: [] as string[],
        followStatus: false,
        officialReply: '',
        fileList: [] as any[]
      }
    }
  },
  methods: {
    async getDetails() {
      if (this.currentId) {
        const result = await doGetSuggestInfo(String(this.currentId));
        if (result.code === 200 && result.data) {
          this.suggestInfo.title = result.data.title;
          this.suggestInfo.typeName = typeMap[result.data.type];
          this.suggestInfo.createTime = result.data.createTime && dayjs(result.data.createTime).format('YYYY-MM-DD HH:mm:ss');
          this.suggestInfo.describe = result.data.describe;
          this.suggestInfo.image = result.data.image ? result.data.image.split(',') : []
          this.suggestInfo.followStatus = result.data.followStatus;
          this.suggestInfo.officialReply = result.data.officialReply;
          this.suggestInfo.fileList = result.data.officialImage ? result.data.officialImage.split(',').map((item: string) => ({ name: item, url: item })) : []
        }
      }
    },
  },
  mounted() {
    this.getDetails()
  }
}
