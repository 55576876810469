



































import { doGetUserCenterSuggestList } from '@/api/suggest';
import SuggestDetails from './SuggestDetails.vue'

export const typeMap = {
  'NEW_FEATURES': '新特性',
  'FUNCTION_ABNORMAL': '功能异常',
  'SUGGESTION': '建议',
  'OTHER': '其他'
}
export default {
  components: { SuggestDetails },
  data() {
    return {
      searchOptions: {
        title: ''
      },
      suggestData: [],
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 10
      },
      typeMap,
      showDialog: false,
      currentId: ''
    }
  },
  methods: {
    closeDialog() {
      this.showDialog = false
    },
    previewDialog(id) {
      this.currentId = id;
      this.showDialog = true
    },
    handleSizeChange(pageSize: number) {
      this.pagination.currentPage = 1;
      this.pagination.pageSize = pageSize;
      this.initialData()
    },
    handleCurrentChange(currentPage: number) {
      this.pagination.currentPage = currentPage;
      this.initialData()
    },
    confirmFeedBack() { },
    async initialData() {
      let data = [], total = 0;
      try {
        const result = await doGetUserCenterSuggestList({ current: this.pagination.currentPage, size: this.pagination.pageSize });
        if (result.data) {
          data = result.data.records;
          total = result.data.total;
        }
      } finally {
        this.suggestData = data;
        this.pagination.total = total;
      }
    }
  },
  mounted() {
    this.initialData()
  },
}
