import { render, staticRenderFns } from "./suggest.vue?vue&type=template&id=484b54bb&scoped=true&"
import script from "./suggest.vue?vue&type=script&lang=ts&"
export * from "./suggest.vue?vue&type=script&lang=ts&"
import style0 from "./suggest.vue?vue&type=style&index=0&id=484b54bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484b54bb",
  null
  
)

export default component.exports